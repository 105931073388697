/* Header.css */
.header-main-container {
  background-color: #007bff;
  color: black;

}

.fixed-top {
  color: rgb(255, 255, 255);
  font-family: Garamond sans-serif;
  font-size: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#basic-nav-dropdown {
  color: white;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.navbar {
  background-color: transparent;
  border: none;
}

.navbar-brand {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.navbar-nav {
  margin-left: auto;
}

.nav-link {
  color: #fff;
  margin: 0 10px;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #ffcc00;
}

.navbar-toggler-icon {
  background-color: #fff;
}

.bg-body-tertiary {
  height: 10vh;
}


.nav-link {
  color: #fff;
  margin: 0 10px;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.nav-link:hover {
  color: #ffcc00;
  transition: all 0.3s ease;
}

.nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.hover-glittery-effect {
  position: relative;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  transition: color 0.3s ease;

}

.hover-glittery-effect:hover {
  color: #f06;
}



.nav-link:hover::before {
  transform: scaleX(1);
  transform-origin: right;
}

.nav-dropdown-item {
  color: #333;
  padding: 12px 20px;
  transition: background-color 0.2s, color 0.2s;
  animation: fadeIn 0.2s ease-in-out;
  animation-fill-mode: both;
  border-radius: 8px;
  /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}

.nav-dropdown-item:hover {
  background-color: #007bff;
  color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .nav-dropdown-item {
    padding: 10px 15px;
  }
}

@media (max-width: 768px) {
  .navbar-toggler {
    background-color: #ffffff;
  }

  .navbar-collapse {
    background: #25D366;
    border-top: none;
  }

  .navbar-nav {
    margin-left: auto;
  }

  .navbar-nav .nav-link {
    color: #333;
    transition: color 0.3s;
  }

  .navbar-nav .nav-link:hover {
    color: #007bff;
  }
}

.LoginAdminButton {
  background: rgb(145 235 79 / 50%);
  /* Green background with transparency */
  color: white;
  font-family: inherit;
  /* Adjusted padding */
  padding: 0px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 2.8em;
}

/* Add a media query for screens smaller than 768px (typical smartphones) */
@media screen and (max-width: 768px) {
  .LoginAdminButton {
    padding: 0px 15px;
    font-size: 12px;
    height: 2.5em;
  }
}

.LoginAdminButton:hover {
  transform: translate(-0.05em, -0.05em);
}

.LoginAdminButton:active {
  transform: translate(0.05em, 0.05em);
}

.LoginAdminButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
  border-radius: inherit;
}

.unityLogo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: -9px;
  margin-bottom: -9px;
  border-radius: 2px;
}

.hover-glittery-effect {
  transition: transform 0.5s ease-out;
}

.hover-glittery-effect:hover {
  transform: scale(1.01);
}