.timeline-title {
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.timeline-list {
    --col-gap: 2rem;
    --row-gap: 2rem;
    --line-w: 0.25rem;
    display: grid;
    grid-template-columns: var(--line-w) 1fr;
    grid-auto-columns: max-content;
    column-gap: var(--col-gap);
    list-style: none;
    width: min(60rem, 90%);
    margin-inline: auto;
}

.UnityWhyTab {
    margin-top: 6%;
    background: rgba(36, 59, 85, 0.9);
    width: 100%;
    height: 20vh;
    color: white;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 5px;
}

.UnityWhyTab h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 160px;
    text-align: center;
    margin: auto;
    white-space: nowrap;
    padding-bottom: 13px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.UnityWhyTab h1:before,
.UnityWhyTab h1:after {
    background-color: rgba(197, 0, 0, 0.9);
    /* Adjusted color with transparency */
    content: '';
    display: block;
    height: 3px;
    width: 75px;
    margin-bottom: 5px;
}

.UnityWhyTab h1:after {
    background-color: rgba(98, 190, 30, 0.9);
    /* Adjusted color with transparency */
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 0.25em;
}


.headingOfHistory {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: white;
}

/* line */
.timeline-list::before {
    content: "";
    grid-column: 1;
    grid-row: 1 / span 20;
    background: rgb(225, 225, 225);
    border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
.timeline-item:not(:last-child) {
    margin-bottom: var(--row-gap);
}

/* card */
.timeline-item {
    grid-column: 2;
    --inlineP: 1.5rem;
    margin-inline: var(--inlineP);
    grid-row: span 2;
    display: grid;
    grid-template-rows: min-content min-content min-content;
}

/* date */
.timeline-date {
    --dateH: 3rem;
    height: var(--dateH);
    margin-inline: calc(var(--inlineP) * -1);
    text-align: center;
    background-color: var(--accent-color);
    color: white;
    font-size: 1.25rem;
    font-weight: 700;
    display: grid;
    place-content: center;
    position: relative;
    border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
.timeline-date::before {
    content: "";
    width: var(--inlineP);
    aspect-ratio: 1;
    background: var(--accent-color);
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
    position: absolute;
    top: 100%;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    right: 0;
}

/* circle */
.timeline-date::after {
    content: "";
    position: absolute;
    width: 2rem;
    aspect-ratio: 1;
    background: var(--bgColor);
    border: 0.3rem solid var(--accent-color);
    border-radius: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
.timeline-title,
.timeline-description {
    background: var(--bgColor);
    position: relative;
    padding-inline: 1.5rem;
}

.timeline-title {
    overflow: hidden;
    padding-block-start: 1.5rem;
    padding-block-end: 1rem;
    font-weight: 500;
}

.timeline-description {
    padding-block-end: 1.5rem;
    font-weight: 300;
}

/* shadows */
.timeline-title::before,
.timeline-description::before {
    content: "";
    position: absolute;
    width: 90%;
    height: 0.5rem;
    background: rgba(0, 0, 0, 0.5);
    left: 50%;
    border-radius: 50%;
    filter: blur(4px);
    transform: translate(-50%, 50%);
}

.timeline-title::before {
    bottom: calc(100% + 0.125rem);
}

.timeline-description::before {
    z-index: -1;
    bottom: 0.25rem;
}

@media (min-width: 40rem) {


    .timeline-list {
        grid-template-columns: 1fr var(--line-w) 1fr;
    }

    .timeline-list::before {
        grid-column: 2;
    }

    .timeline-item:nth-child(odd) {
        grid-column: 1;
    }

    .timeline-item:nth-child(even) {
        grid-column: 3;
    }

    .timeline-item:nth-child(2) {
        grid-row: 2/4;
    }

    .timeline-item:nth-child(odd) .date::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        left: 0;
    }

    .timeline-item:nth-child(odd) .date::after {
        transform: translate(-50%, -50%);
        left: calc(100% + var(--col-gap) + var(--line-w) / 2);
    }

    .timeline-item:nth-child(odd) .date {
        border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
    }
}



@media screen and (max-width: 600px) {



    .UnityWhyTab {
        margin-top: 10%;

    }
}

.credits {
    margin-top: 1rem;
    text-align: right;
}

.credits-link {
    color: var(--color);
}

.historyPara {
    font-size: 12px;

}