.manual-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-container {
  display: flex;
  overflow: hidden;
}

.carousel-slider {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-slide {
  position: relative;
  flex: 0 0 100%;
}

.carousel-slide img {
  width: 100%;
  height: auto;
  display: block;
}

.animated-text {
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: left;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 60px 0px 0px 20px;
}

.big-text {
  font-size: 2.3rem;
  font-weight: 1000;
  margin-bottom: 8px;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: black;
  border: none;
  border-radius: 10%;
  padding: 3px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 18px;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.prev-button:hover,
.next-button:hover {
  background-color: #ccc;
}

@media (max-width: 768px) {
  .big-text {
    font-size: 18px;
  }

  .prev-button,
  .next-button {
    padding: 2px;
    font-size: 10px;
  }
}