.ReactModal__Overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ReactModal__Content {
    z-index: 1001;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
}

.close-modal-button {
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 2px;
    /* position: absolute; */
    background-color: #00483a;
    border: none;
    color: #fff;
    padding: 15px;
    width: 100%;
    transition-duration: 0.4s;
    border-radius: 4px;
    bottom: 0;
    transition: background-color 0.3s, transform 0.3s;
    transform: scale(1);
}

.pdf-iframe {
    width: 100%;
    height: 80%;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .ReactModal__Content {
        padding: 10px;
        max-width: 95%;
    }

    .close-modal-button {
        font-size: 12px;
        padding: 10px;
    }

    .pdf-iframe {
        height: 70%;
    }
}