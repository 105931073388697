 .business-page {
     background-color: #f4f4f4;
     padding: 20px;
     font-family: "Times New Roman", Times, serif;
 }


 .page-header {
     text-align: center;
     background-color: rgba(37, 211, 102, 0.8);
     color: #fff;
     padding: 40px 0;
     backdrop-filter: blur(10px);
     border-radius: 10px;
     box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
 }

 .page-header h1 {
     font-size: 28px;
     margin: 10px 0;
     font-family: "Times New Roman", Times, serif;
 }

 .page-header p {
     font-size: 16px;
     margin: 0;
 }

 .business-description {
    flex: 1;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9); /* Adjusted background color with transparency */
    border-radius: 10px; /* Rounded corners for glass effect */
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37); /* Add box shadow for depth */
    backdrop-filter: blur(10px); /* Apply backdrop-filter for frosted glass effect */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .business-description:hover {
    transform: translateY(-2px); /* Add hover effect for elevation */
  }
  
  .business-description h2 {
    font-size: 24px; /* Increased font size for heading */
    margin: 10px 0;
    color: #333; /* Adjusted heading color */
  }
  
  .business-description p {
    font-size: 16px;
    margin: 10px 0;
    color: #555; /* Adjusted paragraph text color */
  }
  .business-excellence {
    flex: 1;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9); /* Adjusted background color with transparency */
    border-radius: 10px; /* Rounded corners for glass effect */
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37); /* Add box shadow for depth */
    backdrop-filter: blur(10px); /* Apply backdrop-filter for frosted glass effect */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .business-excellence:hover {
    transform: translateY(-2px); /* Add hover effect for elevation */
  }
  
  .business-excellence h2 {
    font-size: 24px; /* Increased font size for heading */
    margin: 10px 0;
    color: #333; /* Adjusted heading color */
  }
  
  .business-excellence ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #555; /* Adjusted list item color */
  }
  
 .testimonial {
     text-align: center;
     margin: 40px 0;
 }

 .testimonial h2 {
     font-size: 20px;
     margin: 10px 0;
 }

 .testimonial-card {
     padding: 20px;
     background-color: #fff;
     border-radius: 8px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 }

 .testimonial-text {
     font-size: 16px;
     color: #333;
 }

 .testimonial-author {
     font-size: 14px;
     color: #777;
     margin-top: 10px;
 }

 .contact-us {
     text-align: center;
     margin: 40px 0;
 }

 .contact-us h2 {
     font-size: 20px;
     margin: 10px 0;
 }

 .contact-us p {
     font-size: 16px;
     color: #333;
     margin: 10px 0;
 }

 .contact-button {
     background-color: #ff5e14;
     color: #fff;
     font-size: 16px;
     padding: 10px 20px;
     border: none;
     border-radius: 4px;
     cursor: pointer;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
     transition: all ease-in-out 0.2s;
 }


 .breadCrump {
     display: flex;
     align-items: center;
     justify-content: start;
     font-size: 12px;
     font-family: cursive;
     cursor: pointer;
 }

 .breadItems2 {
     transition: all ease-in-out 0.3s;

 }

 .breadItems {

     transition: all ease-in-out 0.3s;

 }

 .breadItems:hover {
     color: #03e9f4;
 }


 .breadItems2:hover {
     color: #03e9f4;

 }



 .contact-button:hover {
     background-color: white;
     color: #ff5e14;
 }

 .business-info {
     display: flex;
     flex-direction: column;
     margin: 40px 0;
     gap: 10px;
 }

 @media only screen and (min-width: 768px) {
     .business-info {
         flex-direction: row;
         justify-content: space-around;
     }
 }