.error-margin-left {
    padding:1rem;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorPage {
    width: 30vw;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ErrorBtn {
    border: none;
    text-decoration: none;
    background-color: #00ADEE;
    color: #fff;
    padding: .5em 2em;
    font-size: 1rem;
    border-radius: 5px;
}

@media only screen and (min-width: 1536px) {
    
    .ErrorBtn {
        font-size: 1.2rem;
    }
}