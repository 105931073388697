 .footer-section {
   padding: 30px 20px;
   background-color: #333;
 }

 h4 {
   color: #fff;
 }

 .paraOfFooter {
   color: #ccc;
 }

 ul.list-unstyled a {
   color: #ccc;
   text-decoration: none;
   transition: color 0.3s;
 }

 .row {
   display: flex;
   align-items: center;
   justify-content: space-around;
 }


 ul.list-unstyled a:houl {
   margin: 0px;
   padding: 0px;
 }

 .footer-section {
   background: #151414;
   position: relative;
 }

 .footer-cta {
   border-bottom: 1px solid #373636;
 }

 .single-cta i {
   color: #ff5e14;
   font-size: 30px;
   float: left;
   margin-top: 8px;
 }

 .cta-text {
   padding-left: 15px;
   display: inline-block;
 }

 .cta-text h4 {
   color: #fff;
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 2px;
 }

 .cta-text span {
   color: #757575;
   font-size: 15px;
 }

 .footer-content {
   position: relative;
   /* z-index: 2; */
   color: white;

 }

 .footer-pattern img {
   position: absolute;
   top: 0;
   left: 0;
   height: 330px;
   background-size: cover;
   background-position: 100% 100%;
 }

 .footer-logo {
   margin-bottom: 30px;
 }

 .footer-logo img {
   max-width: 200px;
 }

 .footer-text p {
   margin-bottom: 14px;
   font-size: 14px;
   color: #7e7e7e;
   line-height: 28px;
 }

 .footer-social-icon span {
   color: #fff;
   display: block;
   font-size: 20px;
   font-weight: 700;
   font-family: 'Poppins', sans-serif;
   margin-bottom: 20px;
 }

 .footer-social-icon a {
   color: #fff;
   font-size: 16px;
   margin-right: 15px;
 }

 .footer-social-icon i {
   height: 40px;
   width: 40px;
   text-align: center;
   line-height: 38px;
   border-radius: 50%;
 }

 .facebook-bg {
   background: #3B5998;
 }

 .twitter-bg {
   background: #55ACEE;
 }

 .google-bg {
   background: #DD4B39;
 }

 .footer-widget-heading h3 {
   color: #fff;
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 40px;
   position: relative;
 }

 .footer-widget-heading h3::before {
   content: "";
   position: absolute;
   left: 0;
   bottom: -15px;
   height: 2px;
   width: 50px;
   background: #ff5e14;
 }

 .footer-widget ul li {
   display: inline-block;
   float: left;
   width: 50%;
   margin-bottom: 12px;
 }

 .footer-widget ul li a:hover {
   color: #ff5e14;
 }

 .footer-widget ul li a {
   color: #878787;
   text-transform: capitalize;
 }

 .subscribe-form {
   position: relative;
   overflow: hidden;
 }

 .subscribe-form input {
   width: 100%;
   padding: 14px 28px;
   background: #2E2E2E;
   border: 1px solid #2E2E2E;
   color: #fff;
 }

 .subscribe-form button {
   position: absolute;
   right: 0;
   background: #ff5e14;
   padding: 13px 20px;
   border: 1px solid #ff5e14;
   top: 0;
 }

 .subscribe-form button i {
   color: #fff;
   font-size: 22px;
   transform: rotate(-6deg);
 }

 .copyright-area {
   background: #202020;
   padding: 25px 0;
 }

 .copyright-text p {
   margin: 0;
   font-size: 14px;
   color: #878787;
 }

 .copyright-text p a {
   color: #ff5e14;
 }

 .footer-menu li {
   display: inline-block;
   margin-left: 20px;
 }

 .footer-menu li:hover a {
   color: #ff5e14;
 }

 .footer-menu li a {
   font-size: 14px;
   color: #878787;
 }

 ver {
   color: #007bff;
 }


 .text-center p {
   color: #ccc;
 }

 footer p:last-child {
   padding-bottom: 20px;
 }

 @media (max-width: 768px) {
   .footer-cta .single-cta {
     text-align: center;
     margin-bottom: 20px;
   }
 }

 @media (min-width: 769px) {
   .footer-cta .single-cta {
     float: left;
   }
 }


 .card1 {
   width: fit-content;
   height: fit-content;
   background-color: rgb(238, 238, 238);
   display: flex;
   align-items: center;
   flex-direction: row;
   justify-content: center;
   padding: 25px 25px;
   gap: 20px;
   box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
 }

 .socialContainer {
   width: 52px;
   height: 52px;
   background-color: rgb(44, 44, 44);
   display: flex;
   align-items: center;
   justify-content: center;
   overflow: hidden;
   transition-duration: .3s;
 }

 /* instagram*/
 .containerOne:hover {
   background-color: #d62976;
   transition-duration: .3s;
 }

 /* twitter*/
 .containerTwo:hover {
   background-color: #00acee;
   transition-duration: .3s;
 }

 /* linkdin*/
 .containerThree:hover {
   background-color: #0072b1;
   transition-duration: .3s;
 }

 /* Whatsapp*/
 .containerFour:hover {
   background-color: #128C7E;
   transition-duration: .3s;
 }

 .socialContainer:active {
   transform: scale(0.9);
   transition-duration: .3s;
 }

 .socialSvg {
   width: 17px;
 }

 .socialSvg path {
   fill: rgb(255, 255, 255);
 }

 .socialContainer:hover .socialSvg {
   animation: slide-in-top 0.3s both;
 }

 @keyframes slide-in-top {
   0% {
     transform: translateY(-50px);
     opacity: 0;
   }

   100% {
     transform: translateY(0);
     opacity: 1;
   }
 }

 /* Styles for .footer-section, .footer-widget-heading, .footer-pattern, .footer-logo, etc. */

/* Media queries for responsiveness */
@media (max-width: 575.98px) {
  .footer-widget-heading h3 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .footer-widget-heading h3::before {
    width: 30px;
  }
  .footer-pattern img {
    display: none;
  }
  .footer-logo img {
    max-width: 150px; /* Adjust according to your design */
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .footer-widget-heading h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .footer-widget-heading h3::before {
    width: 40px;
  }
  .footer-pattern img {
    height: 250px; /* Adjust as needed */
  }
  .footer-logo img {
    max-width: 180px; /* Adjust according to your design */
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .footer-widget-heading h3 {
    font-size: 22px;
    margin-bottom: 40px;
  }
  .footer-widget-heading h3::before {
    width: 50px;
  }
  .footer-pattern img {
    height: 300px; /* Adjust as needed */
  }
  .footer-logo img {
    max-width: 200px; /* Adjust according to your design */
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .footer-widget-heading h3 {
    font-size: 24px;
    margin-bottom: 50px;
  }
  .footer-widget-heading h3::before {
    width: 60px;
  }
  .footer-pattern img {
    height: 330px; /* Adjust as needed */
  }
}
