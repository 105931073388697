 .testimonial-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.arrow {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.testimonial-content {
  text-align: center;
  margin: 0 20px;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.occupation {
  color: #888;
  font-style: italic;
}

.testimonial-text {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

.rating {
  display: flex;
  justify-content: center;
}

.rating span {
  font-size: 20px;
  color: #f39c12;
}

@media (max-width: 600px) {
  .testimonial-card {
    flex-direction: column;
  }

  .testimonial-content {
    margin: 0;
  }

  /* .arrow {
    display: none;
  } */
}

.testimonials-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
