.solutions {
    width: 100vw;
    height: auto;
}





.soln {
    margin-top: 6%;


}

@media screen and (max-width: 600px) {
    .SolutionsTab {
        margin-top: 25%;
        background-color: #f4f4f4;
        ;
        width: 100%;
        height: 20vh;
        border-radius: 10px;

    }
}