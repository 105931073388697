.WhyUnity {

    width: 100vw;
    height: auto;


}

.ProductsTab {
    margin-top: 6%;
    background-color: #f4f4f4;
    width: 100%;
    height: 40vh;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, transform 0.3s;
}

@media screen and (max-width: 600px) {
    .ProductsTab {
        margin-top: 25%;
        background-color:#f4f4f4;
        ;
        width: 100%;
        height: 20vh;
        border-radius: 10px;
    
    }
}

