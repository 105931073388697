.HomeOfNews {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1320px;
  min-height: 100vh;
  margin: 0 auto;
  font-family: Georgia, 'Times New Roman', Times, serif;
  padding: 10px;
}

.OurNewsTitle {
  text-align: center;
  margin: 10px;
  font-size: 2rem;
  font-weight: 600;
}

.articles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

article {
  width: 100%;
  max-width: 350px;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;
  cursor: pointer;
  position: relative;
}

article:hover {
  background-color: #eaeaea;
}

.figure.imag-container {
  overflow: hidden;
  margin: 0;
}

.News-img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s;
}

.article-body {
  padding: 20px;
}

.article-body>p {
  text-align: start;
  font-size: 16px;
  line-height: 1.5;
  color: black;
  font-family: Garamond, serif;
}

a.read-more {
  text-decoration: none;
  color: #ff5e14;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
}

.icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-left: 5px;
}

@media (max-width: 1024px) {
  .articles {
    justify-content: center;
  }

  article {
    max-width: 45%;
  }
}

@media (max-width: 768px) {
  article {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .articles {
    flex-direction: column;
    align-items: center;
  }

  article {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.imag-container img {
  transition: transform 0.3s;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-wrapper {
  width: 350px;
  height: 440px;
  perspective: 1000px;
  position: relative;
}

.NewsEditBTN {
  border: none;
  background: none;
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transition: transform 0.5s;
}

.front {
  transform: rotateY(0deg);
}

.back {
  overflow-y: auto;
  /* Added for scrollable content */
  transform: rotateY(180deg);
}

.article-wrapper.flipped .front {
  transform: rotateY(-180deg);
}

.article-wrapper.flipped .back {
  transform: rotateY(0deg);
}

.back-content {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.backDesc {
  text-align: start;
  font-size: 16px;
  font-family: Garamond, serif;
  line-height: 1.5;
  font-weight: 500;
  margin: 20px;
  overflow-y: auto;
  max-height: 100%;
}

.back::-webkit-scrollbar {
  width: 12px;
}

.back::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.back::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.back::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.back-button {
  border: none;
  background: transparent;
  color: #5757ff;
  font-size: 1.4rem;
  position: relative;
  margin: -13px 0px 0px -8px;
  cursor: pointer;
}