.main-container {
  max-width: 1140px;
  margin: 0 auto;
}

.main-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.thumb-box {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  overflow: hidden; /* Ensure content doesn't overflow */
}

.thumb-link {
  display: block;
  text-decoration: none;
  color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
}

.thumb-link:hover .overlay-box {
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1));
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure images cover the entire box */
  transition: transform 0.2s ease-out; /* Smooth hover effect */
}

.overlay-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5%;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.75));
  opacity: 0;
  transition: opacity 0.1s ease-out;
}

.overlay-box:hover {
  opacity: 1;
}

.meta {
  font-size: 14px;
  display: block;
}

.main-title {
  font-size: 16px;
  display: block;
  font-weight: 700;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.description {
  color: #ccc;
  font-size: 13px;
  margin-top: 5px;
  display: block;
  max-height: 0;
  line-height: 18px;
  overflow: auto;
  opacity: 0;
  transition: opacity 0.4s ease-out, max-height 0.4s ease-out, margin 0.4s;
}

.thumb-link:hover .description {
  opacity: 1;
  max-height: 100px;
  margin-top: 5px;
  transition: opacity 0.4s ease-out, max-height 0.9s ease-out;
}
