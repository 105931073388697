.whatsapp-icon {
    position: fixed;
    bottom: 5%; /* Adjust as needed */
    right: 5%; /* Adjust as needed */
    z-index: 1000;
  }
  
  .whatsapp-icon-img {
    width: 10vw; /* Adjust as needed */
    height: 10vw; /* Adjust as needed */
    max-width: 60px; /* Adjust as needed */
    max-height: 60px; /* Adjust as needed */
    border-radius: 50%; /* Makes the icon round */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    transition: transform 0.3s ease-in-out; /* Adds a smooth transition effect */
  }
  
  @media screen and (max-width: 768px) {
    .whatsapp-icon {
      bottom: 5%;
      right: 5%;
    }
  
    .whatsapp-icon-img {
      width: 12vw;
      height: 12vw;
      max-width: 50px;
      max-height: 50px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .whatsapp-icon {
      bottom: 5%;
      right: 5%;
    }
  
    .whatsapp-icon-img {
      width: 14vw;
      height: 14vw;
      max-width: 40px;
      max-height: 40px;
    }
  }
  
  .whatsapp-icon-img:hover {
    transform: scale(1.1); /* Scales up the icon on hover */
  }
    