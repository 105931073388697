/* ContactUsForm.css */
.contact-us-form {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 5%;
  margin-bottom: 5%;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.contact-title {
  text-align: center;
  font-family: serif;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10%;
  padding: 10px;
}

.contactButton {
  background: #008a16;
  /* Green background with transparency */
  color: white;
  font-family: inherit;
  padding: 0.45em 3em 0.45em 1.0em;
  /* Adjusted padding */
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 2.8em;
}

.iconButton {
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em rgba(98, 190, 30, 0.5);
  /* Green box shadow */
  right: 0.3em;
  transition: all 0.3s;
}

.contactButton:hover {
  transform: translate(-0.05em, -0.05em);
}

.contactButton:active {
  transform: translate(0.05em, 0.05em);
}

.contactButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
  border-radius: inherit;
}


.contact-form {
  flex: 1;
  padding: 20px;
  margin-right: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: whitesmoke;
  backdrop-filter: blur(10px);
  margin-bottom: 5%;
  color: black;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  font-size: 14px;
}


.contact-details {
  border-radius: 10px;
  flex: 1 1 1px;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-family: Garamond, serif;
  height: 100%;
  color: black;
  background: whitesmoke;
  backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

@media (max-width: 768px) {

  .contact-form,
  .contact-details {
    flex: 1 100%;
    margin-right: 0;
  }
}


.form-body {
  display: flex;
  flex-direction: column;
}

.form-input {
  margin-bottom: 10px;
}

label {
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {

  .contact-form,
  .contact-details {
    max-width: 100%;
    /* Adjust this value as needed */
    margin-top: 2%;
  }
}