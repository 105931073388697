/* Styles for mainProductContainer */
.mainProductContainer {
  height: 100%;
  width: 100%;
}

/* Styles for mobile-carousel-container */
.mobile-carousel-container {
  width: 100%;
  padding: 0;
  margin-top: 5vh;
}

/* Styles for HomepageWhoWeAre section */
.HomepageWhoWeAre {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
}

.whoDesc {
  font-size: 14px;
}

/* General button styles */
.AllBTN {
  color: black;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.AllBTN:hover {
  background-color: white;
  color: #ff5e14;
}

/* Carousel fade effect styles */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 1s;
}

.carousel-fade .carousel-item.active {
  opacity: 1;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active {
  transform: translateX(0);
}

/* Styles for .mainProductContainer, .mobile-carousel-container, .HomepageWhoWeAre, etc. */

/* HeadingStyles section */
.HeadingStyles h1 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 160px;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  padding-bottom: 13px;
}

.HeadingStyles h1:before,
.HeadingStyles h1:after {
  background-color: #c50000;
  content: '';
  display: block;
  height: 3px;
  width: 75px;
  margin-bottom: 5px;
}

.HeadingStyles h1:after {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 0.25em;
}

/* Media queries for responsiveness */
@media (max-width: 575.98px) {
  .HeadingStyles h1 {
    font-size: 16px;
    width: 80px;
    padding-bottom: 6px;
  }

  .HeadingStyles h1:before,
  .HeadingStyles h1:after {
    width: 30px;
  }

  .whoDesc {
    font-size: 13px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .HeadingStyles h1 {
    font-size: 18px;
    width: 100px;
    padding-bottom: 8px;
  }

  .HeadingStyles h1:before,
  .HeadingStyles h1:after {
    width: 40px;
  }

  .whoDesc {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .HeadingStyles h1 {
    font-size: 22px;
    width: 120px;
    padding-bottom: 10px;
  }

  .HeadingStyles h1:before,
  .HeadingStyles h1:after {
    width: 60px;
  }

  .whoDesc {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .HeadingStyles h1 {
    font-size: 24px;
    width: 140px;
    padding-bottom: 12px;
  }

  .HeadingStyles h1:before,
  .HeadingStyles h1:after {
    width: 70px;
  }

  .whoDesc {
    font-size: 14px;
  }
}